import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);
// ReactDOM.render(<App />, document.getElementById("root"));

ReactGA.initialize("G-YDKH131DVR");
// const SendAnalytics = () => {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// };
// reportWebVitals(SendAnalytics);
