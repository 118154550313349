import React, { useEffect } from "react";

const Feedback = () => {
  useEffect(() => {
    window.location.href = "https://feedback.subway.in/feedback";
  }, []);
  return <div></div>;
};

export default Feedback;
