import React, { useEffect, useState } from "react";
import "./index.css";
import ReactGA from "react-ga4";
const Magicpin = ({ isLoad = true }) => {
  const [isLoading, setIsLoading] = useState(isLoad);
  useEffect(() => {
    if (isLoading) {
      window.location.href =
        "https://magicpin.in/collection?collectionName=subwaydelivery-_-collection&isNew=true&context=collection&query=subwaydelivery&utm_source=subwayweb";
      setIsLoading(false);
    } else {
      window.open(
        "https://magicpin.in/collection?collectionName=subwaydelivery-_-collection&isNew=true&context=collection&query=subwaydelivery&utm_source=subwayweb",
        "_blank"
      );
    }
  }, []);
  return (
    <div>
      {isLoading && (
        <div className="magicpin-back" style={{ display: "none" }}>
          <div className="loader"></div>
          {/* <div className="circle-border">
           <div className="logo"></div>
         </div>
         <p>Caution, hot subs ahead.</p> */}
        </div>
      )}
    </div>
  );
};

export default Magicpin;
