import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../components/home";
import Swiggy from "../components/swiggy";
import Zomato from "../components/zomato";
import CampaignLanding from "../components/campaign-landing";
import NotFound from "../components/not-found";
import NewHome from "../components/new-home";
import StoreLocator from "../components/new-home/sections/store-locator";
import FirstSection from "../components/new-home/sections/first";
import SecondSection from "../components/new-home/sections/second";
import PrivacyPolicy from "../components/privacy-policy";
import { Helmet } from "react-helmet";
import SubwayMenu from "../components/new-home/sections/menu";
import Feedback from "../components/feedback";
import TermsAndConditions from "../components/terms-conditions";
import NutritionFileDownload from "../components/nutrition-file-download";
const AppRoutes = () => {
  return (
    <div>
      <Router>
        <Helmet>
          <title>Subway</title>
          <meta name="description" content="Subway Meals" />
          <meta name="keywords" content={`Subway, stores, restaurants`} />
        </Helmet>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <NewHome>
                <FirstSection />
              </NewHome>
            }
          />
          <Route
            exact
            path="/about"
            element={
              <NewHome>
                <SecondSection />
              </NewHome>
            }
          />
          <Route exact path="/feedback" element={<Feedback />} />
          <Route
            exact
            path="/menu"
            element={
              <NewHome>
                <SubwayMenu />
              </NewHome>
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={
              <NewHome>
                <PrivacyPolicy />
              </NewHome>
            }
          />
          <Route
            exact
            path="/terms-and-conditions"
            element={
              <NewHome>
                <TermsAndConditions />
              </NewHome>
            }
          />

          <Route exact path="/s" element={<CampaignLanding />} />
          <Route
            exact
            path="/nutrition-allergen-info"
            element={<NutritionFileDownload />}
          />
          <Route
            exact
            path="/stores"
            element={
              <NewHome>
                <StoreLocator />
              </NewHome>
            }
          />
          <Route
            exact
            path="/stores/:state"
            element={
              <NewHome>
                <StoreLocator />
              </NewHome>
            }
          />
          <Route
            exact
            path="/stores/:state/:city"
            element={
              <NewHome>
                <StoreLocator />
              </NewHome>
            }
          />

          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
