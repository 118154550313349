import React, { useEffect, useState } from "react";
import "./index.css";
import swiggy from "../../resources/images/swiggy.webp";
import zomato from "../../resources/images/zomato.webp";
import magicpin from "../../resources/images/magicpin.webp";
import { useNavigate } from "react-router-dom";
import subwayLogo from "../../resources/images/subway-logo.webp";
import ReactGA from "react-ga4";
import Swiggy from "../swiggy";
import Zomato from "../zomato";
import Magicpin from "../magicpin";

const CampaignLanding = () => {
  const navigate = useNavigate();
  const [showSwiggy, setShowSwiggy] = useState(false);
  const [showZomato, setShowZomato] = useState(false);
  const [showMagicpin, setShowMagicpin] = useState(false);
  const swiggyHandler = () => {
    ReactGA.event({
      category: "Swiggy Button Clicks",
      action: "Redirect to Swiggy",
      label: "Swiggy",
    });
    setShowSwiggy(true);
  };
  const zomatoHandler = () => {
    ReactGA.event({
      category: "Zomato Button Clicks",
      action: "Redirect to Zomato",
      label: "Zomato",
    });
    setShowZomato(true);
  };
  const magicpinHandler = () => {
    ReactGA.event({
      category: "Magicpin Button Clicks",
      action: "Redirect to Magicpin",
      label: "Magicpin",
    });
    setShowMagicpin(true);
  };

  return (
    <>
      {!showSwiggy && !showZomato && !showMagicpin && (
        <div className="campagin-body">
          <img src={subwayLogo} className="subway-logo" alt="subway" />
          {/* <div className="content-con"> */}
          <div className="title">
            <div className="mobile-text">
              Click
              <div>to order</div>
              <div>super</div>
              <div>simply at</div>
            </div>
            <div className="desktop-text">
              Click to order
              <div>super simply at</div>
            </div>
          </div>

          {/* <h1 className="title">Click to order super simply at</h1> */}
          <div className="btn-con">
            <button
              className="custom-btn swiggy btn-radius"
              onClick={swiggyHandler}
            >
              <img src={swiggy} alt="subway" />
            </button>
            <button
              className="custom-btn zomato btn-radius"
              onClick={zomatoHandler}
            >
              <img src={zomato} alt="subway" />
            </button>
            <button
              className="custom-btn magicpin btn-radius"
              onClick={magicpinHandler}
            >
              <img src={magicpin} alt="subway" />
            </button>
          </div>
        </div>
        // </div>
      )}

      {showSwiggy && <Swiggy />}
      {showZomato && <Zomato />}
      {showMagicpin && <Magicpin />}
    </>
  );
};

export default CampaignLanding;
