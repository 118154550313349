import React, { useState, useEffect } from "react";
import "./index.css";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ link }) => {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(getInitialScale()); // Adjust the initial scale factor
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  function getInitialScale() {
    return window.innerWidth > 768 ? 1.8 : 0.8; // Set different scale factors based on screen size
  }

  useEffect(() => {
    function handleResize() {
      setScale(getInitialScale());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Document file={link} onLoadSuccess={onDocumentLoadSuccess}>
        {[...Array(numPages).keys()].map((pageNumber) => (
          <Page
            key={`page_${pageNumber + 1}`}
            pageNumber={pageNumber + 1}
            scale={scale} // Set the scale property here
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        ))}
      </Document>
    </div>
  );
};

export default PdfViewer;
