import React, { useState, useRef } from "react";
import "./unsubscribe.css";
import { TextField, Box, Button } from "@mui/material";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const OtpInput = ({ mobileNo, setIsOtpSent, setApiMessage }) => {
  const [otp, setOtp] = useState(new Array(5).fill(""));
  const [isApiCall, setIsApiCall] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== "" && index < 4) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const verifyOtp = () => {
    const processedOtp = otp.join("");
    const payload = {
      otp: processedOtp,
      contactNo: mobileNo,
    };
    setIsApiCall(true);
    plainApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.VERIFY_OTP}`,
      payload
    ).then((res) => {
      if (res.message) {
        setApiMessage({
          type: "error",
          message: res.message,
        });
      } else {
        setApiMessage({
          type: "success",
          message: "You have successfully opted out",
        });
        setIsSuccess(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      setIsApiCall(false);
    });
  };
  return (
    <div>
      {isSuccess ? (
        <div className="otp-con">
          <CheckCircleOutlineIcon
            color="success"
            sx={{ fontSize: "150px", margin: "auto" }}
          />
        </div>
      ) : (
        <div className="otp-con">
          <h2 className="unsubs-header">Verify Number</h2>
          <p className="otp-tagline">
            We have sent an OTP to the mobile number: <br /> +91 {mobileNo}
          </p>

          <Box
            display="flex"
            justifyContent="space-between"
            className="otp-fields-con"
          >
            {otp.map((digit, index) => (
              <TextField
                key={index}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                inputRef={(el) => (inputsRef.current[index] = el)}
                sx={{ mr: { md: 3, xs: 1 } }}
              />
            ))}
          </Box>
          <Button
            variant="contained"
            sx={{ mt: 5 }}
            onClick={verifyOtp}
            disabled={otp.join("").length < 5 || isApiCall}
          >
            Verify
          </Button>
        </div>
      )}
    </div>
  );
};

export default OtpInput;
