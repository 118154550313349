import React, { useState, useEffect } from "react";
import "./index.css";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { REGIONS } from "./config";
import { allRegions } from "../../../../utils/constants";
import ClearIcon from "@mui/icons-material/Clear";
import { HTTP_METHODS, plainApi } from "../../../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../../../utils/endpoints";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LOGO from "../../../../resources/images/bg-logo.webp";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import productImage from "../../../../resources/images/about-img.webp";
import CommonFooter from "../../../common-footer";

const StoreLocator = ({ value }) => {
  const { state, city } = useParams();
  const [filters, setFilters] = useState({
    state: state ? state : "",
    city: city ? city : "",
    region: "",
  });
  const [config, setConfig] = useState({
    getData: !!state,
  });
  const [stateList, setStateList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [stateCitesData, setStateCityData] = useState({});
  const [storeData, setStoreData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [pages, setPages] = useState(1);
  const [pageArray, setPageArray] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(null);
  const [submitClicked, setSubmitClicked] = useState(false);
  const navigate = useNavigate();
  const observer = React.useRef();
  const getStoreData = (pg) => {
    if (!pageArray.includes(pg) && filters.state) {
      const params = {
        page: pg,
        limit: 1000,
        // sortBy: "state,city",
      };
      for (let filter in filters) {
        if (filters[filter]) {
          params[filter] = filters[filter];
        }
      }
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.STORE_LIST}`,
        null,
        params
      )
        .then((response) => {
          if (response) {
            setTotalPages(response?.totalPages + 1);
            let array = [...pageArray];
            array.push(pg);
            setPageArray(array);
            if (pages == 1) {
              setStoreData(response.results);
            } else {
              setStoreData([...storeData, ...response.results]);
            }
          } else {
            console.error("Fetching Store failed");
          }
        })
        .catch((err) => {
          console.error("Fetching Store failed");
        });
    }
    // else {
    //   setStoreData([]);
    // }
  };
  const lastItemRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPages((pages) => pages + 1);
          setRefresh(Math.random());
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );
  const reloadData = () => {
    setPages(1);
    setTotalPages(null);
    setPageArray([]);
  };
  const getStateList = () => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.STATE_CITIES_LIST}`)
      .then((res) => {
        if (res?.null) {
          delete res.null;
        }
        if (res?.Western) {
          delete res?.Western;
        }
        setStateCityData(res);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (Object.keys(stateCitesData).length > 0) {
      setStateList(Object.keys(stateCitesData).sort());
    }
  }, [stateCitesData]);
  useEffect(() => {
    if (filters.state) {
      const state = filters.state;
      const list = stateCitesData[state];
      setCitiesList(list);
    }
  }, [filters.state, stateCitesData]);

  useEffect(() => {
    if (!config.getData) {
      return;
    }
    if (totalPages == null) {
      getStoreData(pages);
    } else {
      if (pages < totalPages) {
        setHasMore(true);

        getStoreData(pages);
      } else {
        setHasMore(false);
      }
    }
  }, [refresh, totalPages, config.getData]);
  useEffect(() => {
    if (!config.getData) {
      return;
    }
    if (pageArray?.length == 0) {
      getStoreData(1);
    }
  }, [pageArray]);
  useEffect(() => {
    if (submitClicked) {
      // Apply filters and fetch data
      if (filters.state && filters.city) {
        navigate(`/stores/${filters.state}/${filters.city}`);
      } else if (filters.state) {
        navigate(`/stores/${filters.state}`);
      } else {
        navigate("/stores");
      }
      setSubmitClicked(false);
    }
  }, [submitClicked, filters.state, filters.city]);
  useEffect(() => {
    getStateList();
  }, []);
  return (
    <div className="bg-black">
      <Helmet>
        <title>{`Subway Stores`}</title>
        <meta
          name="description"
          content={`Find Subway restaurants near you in ${filters.city}, ${filters.state}. Explore our store locations, addresses, contact information, and more.`}
        />
        <meta
          name="keywords"
          content={`Subway, stores, restaurants, ${filters.city}, ${filters.state}`}
        />

        <link
          rel="canonical"
          href={`/stores/${filters.state}/${filters.city}`}
        />
      </Helmet>
      <div className="store-locator-con">
        <div className="filters-con">
          <p className="title">Store Locator</p>
          <Grid container md={12} sx={{ width: "100%" }} spacing={2}>
            <Grid item md={3.5} xs={12} sm={6}>
              <Autocomplete
                label="State"
                onChange={(e, val) => {
                  setConfig((prevVal) => {
                    return {
                      ...prevVal,
                      getData: false,
                    };
                  });
                  if (val) {
                    setFilters((prevVal) => ({ ...prevVal, state: val }));
                    setCitiesList([]);
                    setFilters((prevVal) => ({ ...prevVal, city: "" }));
                    reloadData();
                  } else {
                    setFilters((prevVal) => ({ ...prevVal, state: "" }));
                  }
                }}
                value={filters?.state || null}
                clearIcon={
                  <ClearIcon
                    fontSize="small"
                    onClick={() => {
                      setCitiesList([]);
                      setFilters((prevVal) => ({
                        ...prevVal,
                        city: "",
                        state: "",
                      }));
                      // reloadData();
                      // setStoreData([]);
                      setConfig((prevVal) => {
                        return {
                          ...prevVal,
                          getData: false,
                        };
                      });
                    }}
                  />
                }
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  width: "80%",
                }}
                options={stateList || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="State" size="small" />
                )}
              />
            </Grid>
            <Grid item md={3.5} xs={12} sm={6}>
              <Autocomplete
                label="City"
                onChange={(e, val) => {
                  if (val) {
                    setConfig((prevVal) => {
                      return {
                        ...prevVal,
                        getData: false,
                      };
                    });
                    setFilters((prevVal) => ({ ...prevVal, city: val }));
                    reloadData();
                  } else {
                    setFilters((prevVal) => ({ ...prevVal, city: "" }));
                  }
                }}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  width: "80%",
                }}
                clearIcon={
                  <ClearIcon
                    fontSize="small"
                    onClick={() => {
                      setConfig((prevVal) => {
                        return {
                          ...prevVal,
                          getData: false,
                        };
                      });
                      reloadData();
                    }}
                  />
                }
                value={filters?.city || null}
                options={citiesList || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="City" size="small" />
                )}
              />
            </Grid>
            <Grid item md={3.5} xs={12} sm={6}>
              <Autocomplete
                label="Area"
                onChange={(e, val) => {
                  if (val) {
                    setFilters((prevVal) => ({
                      ...prevVal,
                      area: val?.value,
                    }));
                    reloadData();
                  } else {
                    setFilters((prevVal) => ({ ...prevVal, area: "" }));
                  }
                }}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  width: "80%",
                }}
                clearIcon={
                  <ClearIcon
                    fontSize="small"
                    onClick={() => {
                      reloadData();
                    }}
                  />
                }
                options={REGIONS || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Area" size="small" />
                )}
              />
            </Grid>
            <Grid item md={1} xs={12} sm={6}>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  if (!filters.state) {
                    setStoreData([]);
                  }
                  setSubmitClicked(true);
                  setConfig((prevVal) => {
                    return {
                      ...prevVal,
                      getData: true,
                    };
                  });
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className="store-details">
          {storeData?.length > 0 ? (
            storeData.map((iter, index) => {
              if (index + 1 === storeData?.length && hasMore) {
                return (
                  <div
                    ref={lastItemRef}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    Loading...
                  </div>
                );
              } else {
                return (
                  <div className="store-content-con">
                    <Grid
                      container
                      md={12}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid item md={8} xs={9}>
                        <div>
                          <div className="icon-con">
                            <LocationOnIcon
                              sx={{ mr: 2 }}
                              className="si-hide-mobile"
                            />
                            <div>
                              <p className="address display-name">
                                {iter?.restaurantName}
                              </p>
                              <p className="address">
                                {iter?.address}, {iter?.city}, {iter?.state} -{" "}
                                {iter?.pinCode}
                              </p>{" "}
                            </div>
                          </div>
                          {iter?.storeNumber && (
                            <p className="phone">
                              Store Number: {iter?.storeNumber}
                            </p>
                          )}
                          {iter?.storeHours && (
                            <p className="phone">{iter?.storeHours}</p>
                          )}
                          <div className="map-con">
                            {iter?.contactNumber && (
                              <p className="phone">
                                Phone : {iter?.contactNumber}
                              </p>
                            )}
                            {iter?.storeLink && (
                              <a
                                href={`${iter?.storeLink}`}
                                target="_blank"
                                className="location-link "
                              >
                                View on Google Maps
                              </a>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={1.5}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <img src={LOGO} className="sub-logo" alt="subway" />
                      </Grid>
                    </Grid>
                  </div>
                );
              }
            })
          ) : (
            <div className="store-not-found img-container">
              <div className="not-found-text">
                <p>Please select the State and City to list the stores</p>
              </div>
              <div>
                <img
                  src={productImage}
                  className="about-sandwitch-img"
                  alt="subway"
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <CommonFooter />
    </div>
  );
};

export default StoreLocator;
