import React, { useEffect, useState } from "react";
import "./index.css";
import subwayLogo from "../../resources/images/subway-logo.webp";
import logo from "../../resources/images/logo.png";
import MainTabs from "./tabs";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import SideMenu from "../side-menu";
const NewHome = ({ children }) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const tabs = ["Home", "Menu", "About Us", "Store Locator"];

  const changeValueByRoute = (index) => {
    switch (index) {
      case "/about":
        setValue(2);
        break;
      case "/stores":
        setValue(3);
        break;
      case "/menu":
        setValue(1);
        break;
      default:
        setValue(0);
        break;
    }
  };
  useEffect(() => {
    let path = location.pathname;
    if (path.includes("/stores")) {
      path = "/stores";
    }
    changeValueByRoute(path);
  }, []);
  return (
    <>
      <div className="new-home">
        <nav className="main-header">
          <div className="si-hide-web">
            <div className="modile-menu-header">
              <img
                src={subwayLogo}
                width="30%"
                alt="subway"
                onClick={() => {
                  navigate("/");
                  setValue(0);
                }}
              />
              <SideMenu />
            </div>
          </div>
          <div className="si-hide-mobile">
            <img
              src={subwayLogo}
              className="main-sub-logo"
              alt="subway"
              onClick={() => {
                navigate("/");
                setValue(0);
              }}
            />
          </div>
          <div className="si-hide-mobile">
            <div className="tabs-div">
              <MainTabs
                value={value}
                setValue={setValue}
                tabs={tabs}
                navigate={navigate}
              />
            </div>
          </div>
        </nav>
        <div className="content-div">{children}</div>
        {/* <div className="footer">
      <p>
        All rights reserved. SUBWAY® is a Registered Trademark of Subway IP LLC. ©2022 Subway IP LLC., and is licensed to Eversub India Private Limited under an exclusive master franchisee arrangement for the territory of the Republic of India.
      </p>
      <img src={logo} className="sm-logo" />
    </div> */}
      </div>
    </>
  );
};

export default NewHome;
