import cheesyAmerican from "../../../../../resources/menu-images/part1/Cheesy American BBQ.jpg";
import potataoChilliCheese from "../../../../../resources/menu-images/part1/Potatao Chilli Cheese.jpg";
import cheesyPaneerTikka from "../../../../../resources/menu-images/part1/Cheesy Paneer Tikka.jpg";
import cheesyMexican from "../../../../../resources/menu-images/part1/Cheesy Mexican.jpg";
import cheesyChickenTikka from "../../../../../resources/menu-images/part1/Cheesy Chicken Tikka.jpg";
import eggChickenMelt from "../../../../../resources/menu-images/part1/Egg'n Chicken Melt.jpg";
import chickenKeemaMelt from "../../../../../resources/menu-images/part1/Chicken Keema Melt.jpg";
import cheesyLambPepperoni from "../../../../../resources/menu-images/part1/Cheesy Lamb Pepperoni.jpg";

import ChilliCheeseDP from "../../../../../resources/menu-images/part2/ChilliCheeseDP.webp";
import PaneerAchariDP from "../../../../../resources/menu-images/part2/PaneerAchariDP.webp";
import CrunchyMexicanDP from "../../../../../resources/menu-images/part2/CrunchyMexicanDP.webp";
import ChickenTikkaAchariDP from "../../../../../resources/menu-images/part2/ChickenTikkaAchariDP.webp";
import PepperChickenDP from "../../../../../resources/menu-images/part2/PepperChickenDP.webp";
import GreatAmericanBBQ from "../../../../../resources/menu-images/part2/AmericanBBQDP.webp";

export const imageConfig = [
  {
    text: "Potato Chilli Cheese",
    image: potataoChilliCheese,
    description:
      "Hot toasted sub loaded with 1.5x potato patty and real mozz cheese, topped with mint mayo, onions and capsicum and cheese slice",
    ingredients: "Allergens - Contains cereals containing gluten, milk, soy.",
    servingSize: "15cm - 238 g/518 kcal, 30cm - 476 g/1036 kcal.",
  },
  {
    text: "Cheesy Paneer Tikka",
    image: cheesyPaneerTikka,
    description:
      "Double the paneer, with real mozz cheese. Indulge in hot cheesy paneer melt loaded with paneer, tangy tandoori sauce, fresh veggies and cheese slice.",
    ingredients: "Allergens - Contains cereals containing gluten, milk, soy.",
    servingSize: "15cm - 299 g/678 kcal, 30cm - 598 g/1356 kcal",
  },
  {
    text: "Cheesy Mexican",
    image: cheesyMexican,
    description:
      "Have a cheese blast with Three Cheese Grill sandwich. Freshly grilled Italian white bread stuffed with potato patty, lettuce, onion, capsicum and jalapenos, topped with oregano spice mix and the cheesy trio of cheese slice, liquid cheese and mozzarella. Hot and cheesy toasted sub with 1.5x chili bean patty, real mozz cheese, chipotle southwest sauce, tomato, capsicum, onion, olive, jalapeno and cheese slice.",
    ingredients: "Allergens - Contains cereals containing gluten, milk, soy.",
    servingSize: " 15cm - 281 g/597 kcal, 30cm - 562 g/1194 kcal.",
  },
  {
    text: "Cheesy Chicken Tikka",
    image: cheesyChickenTikka,
    description:
      "Double the chicken, with real mozz cheese. Indulge in hot cheesy chicken tikka sub loaded with chicken, tandoori sauce, fresh veggies and cheese slice.",
    ingredients:
      "Allergens - Contains cereals containing gluten, milk, soy, cashew nuts.",
    servingSize: "15cm - 299 g/654 kcal, 30cm - 598g/1308 kcal.",
  },
  {
    text: "Cheesy American BBQ",
    image: cheesyAmerican,
    description: `Hot toasted sub loaded with 1.5x filling and real mozz cheese, topped with BBQ sauce, capsicum, onion, gherkins, eggless mayo and cheese slice.`,
    ingredients: " Allergens - Contains cereals containing gluten, milk, soy.",
    servingSize: "15cm - 230 g/457 kcal, 30cm - 460 g/914 kcal.",
  },
  {
    text: "Egg'n Chicken Melt",
    image: eggChickenMelt,
    description: `Hot toasted sub with a generous 1.5x filling of delicious egg and chicken slice, real mozz cheese topped with southwest chipotle sauce, tomato, capsicum, onion and cheese slice. `,
    ingredients:
      "Allergens - Contains cereals containing gluten, milk, soy, egg.",
    servingSize: "15cm - 292 g/525 kcal, 30cm - 584 g/1050 kcal. ",
  },
  {
    text: "Chicken Keema Melt",
    image: chickenKeemaMelt,
    description: `Hot and cheesy toasted sub with 1.5x chicken keema, real mozz cheese, onion, capsicum, chili mayo and cheese slice.`,
    ingredients: "Allergens - Contains cereals containing gluten, milk, soy.",
    servingSize: "15cm - 233 g/569 kcal, 30cm - 466 g/1138 kcal.",
  },
  {
    text: "Cheesy Lamb Pepperoni",
    image: cheesyLambPepperoni,
    description: `Hot toasted sub filled with 1.5x lamb pepperoni (100% NZ quality), real mozz cheese, eggless mayo, fresh veggies and cheese slice.`,
    ingredients: "Allergens - Contains cereals containing gluten, milk, soy.",
    servingSize: "15cm - 227 g/503 kcal, 30cm - 454 g/1006 kcal.",
  },
];

export const signatureSubsConfig = [
  {
    text: "Chilli Cheese",
    image: ChilliCheeseDP,
    description: `Multigrain, 3-cheese toasted sub with herby aloo patty, jalapeño, onion & capsicum with chilli mayo. `,
    ingredients: " Allergens - Contains wheat, rye, barley, oats, milk & soy.",
    servingSize: "284 g/ 678 kcal.",
  },
  {
    text: "Paneer Achari",
    image: PaneerAchariDP,
    description: `Multigrain toasted sub with chunks of paneer, achari mayo, pickled onion, capsicum, tomato and chilli mayo. `,
    ingredients: " Allergens - Contains wheat, rye, barley, oats, milk & soy.",
    servingSize: "240 g/ 589 kcal.",
  },
  {
    text: "Crunchy Mexican",
    image: CrunchyMexicanDP,
    description: `Multigrain toasted sub with Mexican bean patty, tomato, capsicum, onion, olives, jalapeño, southwest chipotle sauce & crunchy nachos.`,
    ingredients: "Allergens - Contains wheat, rye, barley, oats, milk & soy.",
    servingSize: "295 g/ 702 kcal. ",
  },
  {
    text: "Chicken Tikka Achari",
    image: ChickenTikkaAchariDP,
    description: `Multigrain toasted sub with Tandoori Chicken, achari mayo, pickled onion, capsicum and tomato, topped with chilli mayo for an extra kick. `,
    ingredients: "Allergens - Contains wheat, rye, barley, oats, milk & soy.",
    servingSize: "240 g/ 520 kcal.",
  },
  {
    text: "Pepper Chicken with dip",
    image: PepperChickenDP,
    description: `A flavourful toasted sub loaded with capsicum, olives & jalapeños. This warm sandwich has juicy black pepper chicken paired with creamy Italian sauce and white Italian bread as the base.`,
    ingredients:
      " Allergens - Contains wheat, rye, barley, oats, milk, nuts & soy.",
    servingSize: "189 g/359 kcal.",
  },
  {
    text: "Great American BBQ",
    image: GreatAmericanBBQ,
    description: `Multigrain toasted sub with chicken pepperoni, chicken slice, smoked chicken strips, onion, pickle, capsicum, eggless mayo and barbecue sauce.`,
    ingredients: " Allergens - Contains wheat, rye, barley, oats, milk & soy.",
    servingSize: "253 g/ 539 kcal.",
  },
];
