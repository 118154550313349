export const UNSUBSCRIBE_REASONS = [
  {
    label: "Do Not Wish to Receive Any Communication",
    value: "Do Not Wish to Receive Any Communication",
  },
  { label: "Irrelevant SMS Content", value: "Irrelevant SMS Content" },
  { label: "Not Interested", value: "Not Interested" },
  { label: "Lack of Value", value: "Lack of Value" },
  { label: "Other", value: "Other" },
];
