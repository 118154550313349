import React, { useState } from "react";
import "../index.css";
import desktopText from "../../../resources/images/DesktopText.webp";
import productImage from "../../../resources/images/ProductImage.webp";
import swiggy from "../../../resources/images/swiggy.png";
import zomato from "../../../resources/images/zomato.png";
import magicpin from "../../../resources/images/magicpin.png";
import Swiggy from "../../swiggy";
import Zomato from "../../zomato";
import Magicpin from "../../magicpin";
import mobileText from "../../../resources/images/MobileText.webp";
import logo from "../../../resources/images/bg-logo.webp";
import homeBackgroundImg from "../../../resources/images/home-img.webp";
import customText from "../../../resources/images/home-text.webp";
import { useNavigate } from "react-router-dom";
import CommonFooter from "../../common-footer";

const FirstSection = () => {
  const [showSwiggy, setShowSwiggy] = useState(false);
  const [showZomato, setShowZomato] = useState(false);
  const [showMagicpin, setShowMagicpin] = useState(false);
  const navigate = useNavigate();
  const swiggyHandler = () => {
    setShowSwiggy(true);
  };
  const zomatoHandler = () => {
    setShowZomato(true);
  };
  const magicpinHandler = () => {
    setShowMagicpin(true);
  };

  return (
    <>
      <div className="first-section">
        <div className="content-con">
          <div className="upper-section si-hide-mobile">
            <img
              src={require("../../../resources/images/home-bg-text.webp")}
              alt="subway-text"
              width="80%"
              className="si-hide-mobile"
              style={{ marginLeft: "70px" }}
            />

            <div className="text-con">
              {/* <img src={customText} className="grill-con" alt="subway" /> */}

              <div className="order-btn">
                <div className="custom-btn" onClick={zomatoHandler}>
                  <img src={zomato} alt="zomato" />
                </div>
                <div className="custom-btn" onClick={swiggyHandler}>
                  <img src={swiggy} alt="subway" />
                </div>

                <div className="custom-btn" onClick={magicpinHandler}>
                  <img src={magicpin} alt="subway" />
                </div>
              </div>
              <div className="yellow-border"></div>
            </div>
          </div>
          <div className="si-hide-web ">
            <div className="upper-section">
              <div style={{paddingTop: "20px"}}>
              <img
                src={require("../../../resources/images/mobile-home-text.webp")}
                alt="subway-text"
                width="90%"
                className="si-hide-web"
                style={{ marginLeft: "27px" }}
              />
              </div>
              <div className="text-con">
                {/* <img src={customText} className="grill-con" alt="subway" /> */}

                <div className="order-btn">
                  <div className="custom-btn" onClick={zomatoHandler}>
                    <img src={zomato} alt="zomato" />
                  </div>
                  <div className="custom-btn" onClick={swiggyHandler}>
                    <img src={swiggy} alt="subway" />
                  </div>

                  <div className="custom-btn " onClick={magicpinHandler}>
                    <img src={magicpin} alt="subway" />
                  </div>
                  <div className="yellow-border"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CommonFooter />
      </div>
      {showZomato && <Zomato />}
      {showSwiggy && <Swiggy />}

      {showMagicpin && <Magicpin />}
    </>
  );
};

export default FirstSection;
