export const HTTP_METHODS = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};
export const plainApi = async (method, url, data, params) => {
  let headers = {
    "Content-Type": "application/json",
  };

  if (params) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }

  return fetch(url, {
    method: method,
    body: (data && JSON.stringify(data)) || undefined,
    headers: headers,
    params: params,
  }).then((response) => {
    if (response.status === 401) {
      alert("Ooops! Something went wrong");
    }

    if (response.status === 204) {
      return Promise.resolve("ok");
    } else {
      return response.json();
    }
  });
};
