export const allRegions = {
  Gujarat: [
    "Ahemdabad",
    "Ahmedabad",
    "Ahmedabad ",
    "Anand",
    "Bharuch",
    "Bhavnagar",
    "Bhuj",
    "Bārdoli",
    "Gandhidham",
    "Gandhinagar",
    "Jamnagar",
    "Karzan",
    "Kevadia",
    "Kosamba",
    "Limbdi",
    "Mehsana",
    "Morbi",
    "Nadiād",
    "Narmada",
    "Navsari",
    "Rajkot",
    "Surat",
    "Surat ",
    "Vadodara",
    "Vapi",
  ],
  Karnataka: [
    "BANGALORE",
    "Bangalore",
    "Bangalore ",
    "Basavanagudi",
    "Belgaum",
    "Bengaluru",
    "Kolar",
    "Mangalore",
    "Mangalore ",
    "Manipal",
    "Mysore",
    "Yelahanka",
  ],
  Chhattisgarh: ["Bilaspur", "Raipur"],
  Rajasthan: [
    "Ajmer",
    "Alwar",
    "Jaipur",
    "Jodhpur",
    "Kota",
    "Neemrana",
    "Sri Ganganagar",
    "Udaipur",
    "jaipur",
  ],
  "Andhra Pradesh": [
    "Guntur",
    "Kakinada",
    "Rajamahendravaram",
    "Rajamundhry",
    "Tirupati",
    "Vijayawada",
    "Visakhapatnam",
  ],
  Jharkhand: ["Jamshedpur", "Ranchi"],
  "Rajasthan ": ["Udaipur"],
  Maharashtra: [
    "Aurangabad",
    "Dombivili",
    "Dombivli",
    "Goregaon",
    "Kalyan Mumbai",
    "Kharghar",
    "Kolhapur",
    "MUMBAI",
    "Mira Road ",
    "Mumbai",
    "Mumbai ",
    "Mumbai Vile Parle",
    "Mumbai | Malad",
    "Mumbai | Mulund",
    "Mumbai | Thane",
    "Mumbai | Virar",
    "Mumbai| Thane",
    "Nagpur",
    "Nashik",
    "Navi Mumbai",
    "Palghar",
    "Panvel, Dist Raigad",
    "Pune",
    "Shirdi",
    "Sāngli",
    "THANE",
    "Thane",
    "VASAI",
    "ambegoan Pun",
    "aurangabad",
    "mumbai",
  ],
  "Jammu and Kashmir": ["Jammu"],
  Chandigarh: ["Chandigarh", "chandigarh"],
  Kerala: [
    "Calicut",
    "Cochin",
    "Kozhikode",
    "Thiruvananthapuram",
    "Thrissur",
    "Trivandrum",
  ],
  Punjab: [
    "Amritsar",
    "Barnala",
    "Batala",
    "Bhatinda",
    "Chaklan",
    "Dasuya",
    "Derabassi, Mohali",
    "Faridkot",
    "Fatehgarh Sahib",
    "Ferozepur",
    "Gurdaspur",
    "Hoshiarpur",
    "Iudhiana",
    "Jagraon",
    "Jalandhar",
    "Kapurthala",
    "Khamano",
    "Khanna",
    "Kharar",
    "LUDHIANA",
    "Ludhaina",
    "Ludhana",
    "Ludhiana",
    "Ludhiāna",
    "Moga",
    "Mohali",
    "Muktsar",
    "Nabha",
    "Nakodar",
    "Nawanshahr",
    "Pathankot",
    "Patiala",
    "Phagwara",
    "Punjab",
    "Rajpura",
    "RupNagar",
    "SAS Nagar, Mohali",
    "Sangrur",
    "Tarn Taran",
    "Zira",
    "Zirakhpur",
    "Zirakpur",
    "amritsar",
    "panchkula",
  ],
  Goa: [
    "Alto Porvorim",
    "Anjuna",
    "Calungute",
    "Margaon",
    "Panaji",
    "Vasco Da Gama",
    "Vasco da Gama",
  ],
  Haryana: [
    "Ambala",
    "Ambala City",
    "Faridabad",
    "Farīdābād",
    "GURUGRAM",
    "Gurgaon",
    "Gurugram",
    "Hisar",
    "KARNAL",
    "Karnal",
    "Kurukshetra",
    "Manesar",
    "Panchkula",
    "Panipat",
    "Rewari",
    "Rohtak",
    "Sirsa",
    "Sonipat",
    "Yamunānagar",
    "gurugram",
  ],
  "Maharashtra ": ["Nagpur", "Nashik"],
  Uttaranchal: [
    "Dehradhun",
    "Dehradun",
    "Haldwani",
    "Haridwar",
    "Mainital",
    "Roorkee",
  ],
  Pondicherry: ["Pondicherry"],
  Kashmir: ["Jammu"],
  Assam: ["Guwahati", "Silchar"],
  "Madhya Pradesh": [
    "Bhopal",
    "Gwalior",
    "Indore",
    "Jabalpur",
    "Rewa",
    "Satna",
    "indore",
  ],
  "Tamil Nadu": [
    " Chennai",
    "Chennai",
    "Coimbatore",
    "Hosūr",
    "Kattankulathur",
    "Madurai",
    "Salem",
    "Tiruppur",
    "Trichy",
    "Vellore",
    "coimbatore",
  ],
  "West Bengal": [
    "Darjeeling",
    "Durgapur",
    "Howrah",
    "Kharagpur",
    "Kolkata",
    "Kolkatta",
    "Siliguri",
  ],
  "Himachal Pradesh": ["Mcleodganj", "Solan"],
  Delhi: ["Delhi", "Malviya Nagar", "NCR Delhi", "New Delhi"],
  Telangana: [
    "Hyderabad",
    "Medak",
    "Secunderabad",
    "Serilingampally Manda",
    "Shankarpally Mandal",
  ],
  Orissa: ["Bhubaneswar"],
  Manipur: ["Imphal"],
  "New Delhi": ["Delhi", "GURGAVA", "New Delhi"],
  "Uttar Pradesh ": ["Ghaziabad"],
  "Uttar Pradesh": [
    "Agra",
    "Allahabad",
    "Bilaspur",
    "Gajraula",
    "Ghaziabad",
    "Gorakhpur",
    "Greater Noida",
    "Greater Noida West",
    "Kanpur",
    "Lucknow",
    "Mathura",
    "Meerut",
    "Moradabad",
    "Muzaffarnagar",
    "NCR - Noida",
    "Noida",
    "Varanasi",
  ],
  Colombo: ["Colombo", "Mount Lavinia"],
  Uttarakhand: ["Dehra Dūn", "Dehradun "],
};
export const PHONE_NO_REG_EX = /^\d{10}$/;
