import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NutritionFileDownload = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const downloadFile = () => {
      const link = document.createElement("a");
      link.href =
        "https://qnet.blob.core.windows.net/central/Nutritional%20%2B%20Allergen%20Chart-%20Version%202.47%20November%20(formatted)%20-%201730719897576.pdf";
      link.download = "Nutritional + Allergen Chart- Version 2.47 November.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    downloadFile();
    const timer = setTimeout(() => {
      navigate("/");
    }, 100);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div>
      <p>Downloading file...</p>
    </div>
  );
};

export default NutritionFileDownload;
