import React from "react";
import AppRoutes from "./routes";
import { createTheme, ThemeProvider } from "@mui/material";
import { Theme } from "./utils/theme-config";

const theme = createTheme(Theme);
function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </div>
  );
}

export default App;
