import React from "react";
import "../../index.css";
import OrderNow from "../order-now";
import TreatAndSide from "../../../../../../resources/menu-images/Treats&Sides.webp";
import { Grid, Typography } from "@mui/material";

const TreatsAndSides = () => {
  return (
    <div>
      <div className="treat-and-sides">
        <Typography
          sx={{
            fontWeight: { md: 500 },
            mb: { md: 2 },
            fontSize: { md: "35px", xs: "20px" },
          }}
        >
          Cookies
        </Typography>
        <div>
          <Typography
            sx={{
              ml: { md: 4, xs: 1 },
              mb: { md: 1 },
              color: "gray",
              fontSize: { md: "25px", xs: "20px" },
            }}
          >
            Oatmeal Raisin
          </Typography>
          <Typography
            sx={{
              ml: { md: 4, xs: 1 },
              mb: { md: 1 },
              color: "gray",
              fontSize: { md: "25px", xs: "20px" },
            }}
          >
            Double Dark Chunk Chocolate
          </Typography>
          <Typography
            sx={{
              ml: { md: 4, xs: 1 },
              mb: { md: 1 },
              color: "gray",
              fontSize: { md: "25px", xs: "20px" },
            }}
          >
            Dark Chunk Chocolate
          </Typography>
        </div>
        <Grid container md={12}>
          <Grid item md={6} sx={{ mt: { md: 10 } }} xs={5}>
            <Typography
              sx={{
                fontWeight: 600,
                mt: { md: 3, xs: 2 },
                mb: { md: 3, xs: 1 },
                fontSize: { md: "25px" },
              }}
            >
              Veg Kababs with dips
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                mt: { md: 3, xs: 2 },
                mb: { md: 3, xs: 1 },
                fontSize: { md: "25px" },
              }}
            >
              Chicken Meatballs with dip
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                mt: { md: 3, xs: 2 },
                mb: { md: 3, xs: 1 },
                fontSize: { md: "25px" },
              }}
            >
              Veggie Toastie with dip
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                mt: { md: 3, xs: 2 },
                mb: { md: 3, xs: 1 },
                fontSize: { md: "25px" },
              }}
            >
              Nachos 'n Dip
            </Typography>
          </Grid>
          <Grid item md={6} xs={7}>
            <img src={TreatAndSide} className="cookies-img" alt="subway" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TreatsAndSides;
