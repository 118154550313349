import React from "react";
import "../../index.css";
import { Typography } from "@mui/material";
import SaladAndWrapsImg from "../../../../../../resources/menu-images/salad-and-wraps/Salad.webp";
import Wrap from "../../../../../../resources/menu-images/salad-and-wraps/Wrap.webp";
import OrderNow from "../order-now";

const SaladAndWraps = () => {
  return (
    <div>
      <div className="salad-and-wraps">
        <Typography sx={{ fontSize: { md: "40px" } }}>
          Make any six inch Create Your Own sub into a delicous salad or wrap
        </Typography>

        <div className="salad-imgs-con">
          <img src={SaladAndWrapsImg} className="salad-img" alt="subway" />

          <img src={Wrap} className="salad-img" alt="subway" />
        </div>
      </div>
    </div>
  );
};

export default SaladAndWraps;
