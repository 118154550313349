import React, { useState } from "react";
import "./index.css";
import { Button } from "@mui/material";

const MainTabs = ({ value, setValue, tabs, navigate }) => {
  const getTabRoute = (index) => {
    switch (index) {
      case 0:
        return "/";
      case 2:
        return "/about";
      case 3:
        return "/stores";
      case 1:
        return "/menu";
      default:
        return "/";
    }
  };
  return (
    <header>
      <nav className="nav-header">
        {tabs.map((tabName, index) => (
          <div
            onClick={() => {
              navigate(getTabRoute(index));
              setValue(index);
            }}
            key={index}
          >
            <div
              className={`nav-link ${value === index ? "selected-tab" : ""}`}
            >
              {tabName}
            </div>
          </div>
        ))}

        <Button
          variant="contained"
          className="si-hide-mobile"
          color="success"
          sx={{ mb: "8px", padding: "7px 16px" }}
          // size="small"
          onClick={() => {
            window.location.href = "https://feedback.subway.in/talktous";
          }}
        >
          Talk to us
        </Button>
      </nav>
    </header>
  );
};

export default MainTabs;
