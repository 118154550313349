import React from "react";
import "../index.css";
import { aboutUsPoints } from "./constants";
import productImage from "../../../resources/images/about-img.webp";
import { useNavigate } from "react-router-dom";
import CommonFooter from "../../common-footer";

const SecondSection = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="si-hide-mobile">
        <div className="second-section">
          <div className="about-us-con">
            {aboutUsPoints.map((point, index) => {
              return (
                <div className="about-us-point" key={index}>
                  <div className="list-wrapper">
                    <p className="list-text">{point}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <div className="img-container">
              <img
                src={productImage}
                className="about-sandwitch-img"
                alt="subway"
              />
            </div>
          </div>
        </div>
        {/* <footer className="about-footer">
          <p>
          All rights reserved. SUBWAY® is a Registered Trademark of Subway IP LLC. ©2022 Subway IP LLC., and is licensed to Eversub India Private Limited under an exclusive master franchisee arrangement for the territory of the Republic of India.
          </p>
          <img src={logo} className="sm-logo" />
        </footer> */}
      </div>
      <div className="si-hide-web">
        <div className="second-section">
          <div className="about-us-point">
            <div className="list-wrapper">
              <p className="list-text">{aboutUsPoints[0]}</p>
            </div>
            <div className="img-container">
              <img
                src={productImage}
                className="about-sandwitch-img"
                alt="subway"
              />
            </div>
            <div className="list-wrapper">
              <p className="list-text">{aboutUsPoints[1]}</p>
            </div>
            <div className="list-wrapper">
              <p className="list-text">{aboutUsPoints[2]}</p>
            </div>
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  );
};

export default SecondSection;
