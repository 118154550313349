import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import "./index.css";
const Swiggy = ({ isLoad = true }) => {
  const [isLoading, setIsLoading] = useState(isLoad);
  useEffect(() => {
    if (isLoading) {
      window.location.href =
        "https://www.swiggy.com/direct/brand/2?source=swiggy-direct&subSource=generic";
      setIsLoading(false);
    } else {
      window.open(
        "https://www.swiggy.com/direct/brand/2?source=swiggy-direct&subSource=generic",
        "_blank"
      );
    }

    // ReactGA.send({ hitType: "pageview", page: "/s", title: "Swiggy" });
  }, []);
  return (
    <div>
      {isLoading && (
        <div className="swiggy-back" style={{ display: "none" }}>
          {/* <div className="loader"></div> */}
          {/* <div className="circle-border">
            <div className="logo"></div>
          </div>
          <p>Caution, hot subs ahead.</p> */}
        </div>
      )}
    </div>
  );
};

export default Swiggy;
