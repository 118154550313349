import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import InfoIcon from "@mui/icons-material/Info";
import StoreIcon from "@mui/icons-material/Store";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
export const SIDE_MENU = () => [
  {
    label: "Home",
    url: "/",
    value: "/",
    logo: <HomeIcon sx={{ mr: 1 }} />,
  },
  {
    label: "Menu",
    url: "/menu",
    value: "/menu",
    logo: <ListIcon sx={{ mr: 1 }} />,
  },
  {
    label: "About Us",
    url: "/about",
    value: "/about",
    logo: <InfoIcon sx={{ mr: 1 }} />,
  },
  {
    label: "Store Locator",
    url: "/stores",
    value: "/stores",
    logo: <StoreIcon sx={{ mr: 1 }} />,
  },
  {
    label: "Talk To Us",
    url: "/talktous",
    value: "/talktous",
    logo: <SupportAgentIcon sx={{ mr: 1 }} />,
  },
];
