import React, { useEffect, useState } from "react";
import "./unsubscribe.css";
import CommonFooter from "../common-footer";
import {
  TextField,
  InputAdornment,
  TextareaAutosize,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { PHONE_NO_REG_EX } from "../../utils/constants";
import OtpInput from "./otp";
import { UNSUBSCRIBE_REASONS } from "./config";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";

const Unsubscribe = () => {
  const [data, setData] = useState({
    name: "",
    contactNo: "",
    reason: "",
    remarks: "",
  });
  const [formValidationError, setFormValidationError] = useState({});
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [apiMessage, setApiMessage] = useState({
    type: "",
    message: "",
  });
  const isFormValid = (formData) => {
    const { name, contactNo, reason } = formData;
    let error = {};
    if (!name) {
      error.name = true;
    }
    if (!contactNo || !contactNo.match(PHONE_NO_REG_EX)) {
      error.contactNo = true;
    }
    if (!reason) {
      error.reason = true;
    }
    return error;
  };

  const onSubmit = () => {
    let errors = isFormValid(data);
    if (Object.keys(errors).length > 0) {
      setFormValidationError(errors);
      return;
    }
    const payload = {};
    for (let ele in data) {
      if (data[ele]) {
        payload[ele] = data[ele];
      }
    }
    plainApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.SEND_OTP}`, payload)
      .then((res) => {
        if (res.message) {
          setApiMessage({
            type: "error",
            message: res.message,
          });
        } else {
          setApiMessage({
            type: "success",
            message: "OTP sent successfully",
          });
          setIsOtpSent(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (formValidationError[name]) {
      delete formValidationError[name];
    }
    if (name === "contactNo" && value.length > 10) {
      return;
    }
    setData((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (apiMessage?.message) {
      setTimeout(() => {
        setApiMessage({
          type: "",
          message: "",
        });
      }, 5000);
    }
  }, [apiMessage?.message]);
  return (
    <div>
      {!isOtpSent && (
        <div className="unsubs-form-con">
          <h2 className="unsubs-header">Opt Out</h2>
          <Grid container md={10} gap={3}>
            <Grid item md={12} xs={12}>
              <TextField
                label="Mobile Number"
                value={data?.contactNo}
                onChange={handleChange}
                name="contactNo"
                size="small"
                type="number"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                error={formValidationError.contactNo}
                helperText={
                  formValidationError.contactNo
                    ? "Mobile No. should be 10 digits"
                    : ""
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                label="Name"
                value={data?.name}
                onChange={handleChange}
                name="name"
                size="small"
                required
                fullWidth
                error={formValidationError.name}
                helperText={formValidationError.name ? "Required" : ""}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl
                fullWidth
                error={formValidationError.reason}
                required
                size="small"
              >
                <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={data?.reason}
                  label="Reason"
                  name="reason"
                  onChange={handleChange}
                >
                  {UNSUBSCRIBE_REASONS.map((ele) => (
                    <MenuItem value={ele.value} key={ele.value}>
                      {ele.label}
                    </MenuItem>
                  ))}
                </Select>
                {formValidationError.reason && (
                  <FormHelperText>Required</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                label="Remarks"
                value={data?.remarks}
                onChange={handleChange}
                name="remarks"
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button variant="contained" onClick={onSubmit}>
                Send OTP
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      {isOtpSent && (
        <OtpInput
          mobileNo={data?.contactNo}
          setIsOtpSent={setIsOtpSent}
          setApiMessage={setApiMessage}
        />
      )}
      {apiMessage?.message && (
        <p
          className={`${
            apiMessage?.type === "error" ? "error-message" : "success-message"
          } message-tag`}
        >
          {apiMessage.message}
        </p>
      )}
    </div>
  );
};

export default Unsubscribe;
