import React, { useState, useEffect } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { SIDE_MENU } from "./config";
import { Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";

const SideMenu = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState("/");
  const onMenuClick = () => {};

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={() => setOpen(!open)}
      onKeyDown={() => setOpen(!open)}
    >
      <List>
        <div className="side-menu-header">
          <img
            src={require("../../resources/images/subway-logo.webp")}
            width="90%"
            alt="subway"
          />
          {/* <IconButton onClick={() => setOpen(!open)}>
            <ArrowBackIosNewIcon />
          </IconButton> */}
        </div>
        <div className="menu-items">
          {SIDE_MENU()?.map((menu, index) => {
            let showMenuOption = true;
            return (
              <>
                {
                  <div
                    key={menu.mapkey}
                    className={
                      menu.value === activeMenu.value &&
                      menu.url !== "/talktous"
                        ? "menu-item menu-item-active"
                        : "menu-item"
                    }
                    onClick={() => {
                      onMenuClick();
                      setActiveMenu(menu);
                      if (menu.url !== "/talktous") {
                        navigate(menu.url);
                      }
                    }}
                  >
                    {menu.url === "/talktous" ? (
                      <Button
                        variant="contained"
                        color="success"
                        // size="small"
                        onClick={() => {
                          window.location.href =
                            "https://feedback.subway.in/talktous";
                        }}
                      >
                        Talk to us
                      </Button>
                    ) : (
                      <div className="menu-name align-center">
                        <span>{menu.logo}</span>
                        <span className="sidebarmenulabel">{menu.label}</span>
                      </div>
                    )}
                  </div>
                }
              </>
            );
          })}
        </div>
      </List>
    </Box>
  );
  useEffect(() => {
    setActiveMenu(
      SIDE_MENU().find((m) => m.url === window.location.pathname) || {}
    );
  }, [SIDE_MENU, window.location.pathname]);
  return (
    <div className="si-hide-web">
      {" "}
      <IconButton onClick={() => setOpen(!open)}>
        {open ? <MenuOpenIcon /> : <MenuIcon fontSize="large" />}
      </IconButton>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        {list("right")}
      </Drawer>
    </div>
  );
};

export default SideMenu;
