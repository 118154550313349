import React from "react";
import "../../index.css";
import FireEMOJI from "../../../../../../resources/menu-images/bottom-section/FireEMOJI.webp";
import CRISPERS from "../../../../../../resources/menu-images/bottom-section/Crispers.webp";
import { Typography } from "@mui/material";
const LastSection = () => {
  return (
    <>
      <span className="menu-tag-line-con">
        <img className="fire-img" src={FireEMOJI} alt="subway" />
        <Typography
          sx={{
            mr: { md: 3, xs: 1, sm: 1 },
            ml: { md: 3, sm: 1 },
            fontSize: { md: "1.5rem", xs: "9px", sm: "15px" },
          }}
        >
          {" "}
          NEW NEW NEW CRISPERS
        </Typography>

        <img className="fire-img" src={FireEMOJI} alt="subway" />
        <Typography
          sx={{
            mr: { md: 3, xs: 1, sm: 1 },
            ml: { md: 3, sm: 1 },
            fontSize: { md: "1.5rem", xs: "9px", sm: "15px" },
          }}
        >
          MUNCH-MUNCH
        </Typography>
        <img className="fire-img" src={FireEMOJI} alt="subway" />
        <Typography
          sx={{
            mr: { md: 3, xs: 1, sm: 1 },
            ml: { md: 3, sm: 1 },
            fontSize: { md: "1.5rem", xs: "9px", sm: "15px" },
          }}
        >
          CAN’T STOP AT JUST ONE
        </Typography>
      </span>
      <div className="menu-last-section">
        <div>
          <Typography sx={{ fontSize: { md: "3rem" } }}>INTRODUCING</Typography>
          <Typography
            sx={{
              fontWeight: 600,
              mt: { md: 2 },
              fontSize: { md: "5.5rem", xs: "2rem" },
            }}
          >
            CRISPERS
          </Typography>
        </div>
        <img src={CRISPERS} className="crispers-img" alt="subway"></img>
      </div>
    </>
  );
};

export default LastSection;
