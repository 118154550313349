import React from "react";
import { Grid, Typography } from "@mui/material";
import NonVegSub from "../../../../../../resources/menu-images/create-own/NonVegSub.webp";
import VegSub from "../../../../../../resources/menu-images/create-own/VegSub.webp";
import OrderNow from "../order-now";

const CreateYourOwn = () => {
  return (
    <div className="create-your-own">
      <Grid
        container
        md={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
          xs={5}
        >
          <div className="float-start">
            <img className="create-own-img" src={VegSub} alt="subway" />
          </div>
          <div className="mr-30">
            <Typography variant="h5" className="menu-dropdown-text veg-text">
              Veggie Delite
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text veg-text">
              Aloo Patty
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text veg-text">
              Corn & Peas
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text veg-text">
              Veg Shami
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text veg-text">
              Paneer Tikka
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text veg-text">
              Chilli Bean Patty
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
          xs={7}
        >
          <div className="float-end">
            <img className="create-own-img" src={NonVegSub} alt="subway" />
          </div>
          <div className="veg-con ml-30">
            <Typography variant="h5" className="menu-dropdown-text">
              Spicy Chicken Keema
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Chicken Slice
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Chicken Meatball
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Roast Chicken
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Peri Peri Chicken
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Tandoori Chicken
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Chicken Teriyaki
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Tuna
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Italian B.M.T (Chicken)
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Italian B.M.T (Lamb)
            </Typography>

            <Typography variant="h5" className="menu-dropdown-text">
              Lamb Pepperoni
            </Typography>
            <Typography
              variant="h5"
              className="menu-dropdown-text"
              sx={{ lineHeight: { xs: "16px", md: "30px" } }}
            >
              Double Egg & Cheese Omelette
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateYourOwn;
